
.article-content {

    .article-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                display: flex;
                flex-direction: column;
                height: 100%;
                background: #ffffff;
            }
        }
    }

    .top-tab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #F1F5FF;
        height: 40px;
        padding: 0 20px 15px;

        ::v-deep .genera-breadcrumb::before {
            height: 0;
        }

        .genera-breadcrumb {
            display: flex;
            align-items: center;
        }
    }

    .main-content {
        margin-top: 20px;
        flex: 1;
        height: 1%;

        .main-wrap {
            width: 900px;
            margin: 0 auto;
            height: 100%;

            .top-title {
                text-align: center;
                font-size: 20px;
                color: #333333;
            }

            .top-author {
                margin-top: 12px;
                text-align: center;
                font-size: 14px;
                color: #666666;
            }

            .top-content {
                margin-top: 20px;

                ::v-deep p {
                    img {
                        max-width: 900px;
                    }
                }
            }
        }
    }
}
